// Generic imports
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

// Other components imports
import Layout from "components/layout"
import PayComponent from "components/PayComponent"
import SEO from "components/seo"

// Redux imports

// Static imports

// Utils imports
import { getOneClass } from "services/kelas"
import { getParameterByName } from "services/utils"

const PayPage = () => {
  const [kelas, setKelas] = useState(null)
  const [amount, setAmount] = useState(null)

  useEffect(() => {
    try {
      setAmount(getParameterByName("amount"))
      const kelasCode = getParameterByName("slug")
      getOneClass(kelasCode)
        .then(resp => {
          if (resp.data) {
            setKelas(resp.data[0])
          } else {
            throw new Error()
          }
        })
        .catch(() => {
          navigate("/")
        })
    } catch {
      navigate("/")
    }
  }, [])

  return (
    <Layout>
      <SEO title="Payment" />
      <PayComponent kelas={kelas} amount={amount} />
    </Layout>
  )
}

export default PayPage
