/* eslint-disable camelcase */
// Generic imports
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useFormik } from "formik"
import { toast } from "react-toastify"

import Button from "../Button"
import Input from "../Reusable/Input"
import { Sidebar, SidebarLeft, SidebarRight } from "../Reusable/Sidebar"
import Image from "../image"
import { Styles } from "../OrderClass/style"
import MustLoggedInWrapper from "components/Reusable/MustLoggedInWrapper"

// Redux imports

// Static imports
import verifiedLogo from "images/verified.png"

// Utils imports
import { dummyKelas } from "data/dummy/dummykelas"
import { orderClass } from "services/order"

const PayComponent = ({ amount, ...props }) => {
  const [kelas, setKelas] = useState(dummyKelas)
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      pemahaman: 50,
      yang_diketahui: "",
      yang_tertarik: "",
      yang_diharapkan: "",
      sumber_info: "",
      jadwal_pilihan_pertama: null,
      jadwal_pilihan_kedua: null,
      paket_pembayaran: 0,
      list_teman: [],
      paket_pertemuan: 0,
      total_price: 0,
      is_followup: true,
    },
    onSubmit: async values => {
      try {
        setLoading(true)
        const resp = await orderClass(values)
        toast.success("Berhasil mendaftar kelas!")
        if (resp.data.token === "TC0") {
          navigate(`/payment/finish/`)
        } else {
          navigate(`/payment?token=${resp.data.token}`)
        }
      } catch {
        toast.error("Gagal mendaftar kelas. Coba beberapa saat lagi")
      } finally {
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    if (props.kelas?.slug) {
      const newKelas = JSON.parse(JSON.stringify(props.kelas))
      newKelas.paket_pembayaran.sort((a, b) => {
        return a.id < b.id
      })
      newKelas.paket_pertemuan.sort((a, b) => {
        return a.banyak_pertemuan - b.banyak_pertemuan
      })
      formik.setValues({
        ...formik.values,
        paket_pertemuan: newKelas.paket_pertemuan[0].id,
        paket_pembayaran: newKelas.paket_pembayaran[0].id,
        jadwal_pilihan_pertama:
          newKelas.jadwal_groups?.length > 0
            ? newKelas.jadwal_groups[0].pk
            : null,
        jadwal_pilihan_kedua:
          newKelas.jadwal_groups?.length > 0
            ? newKelas.jadwal_groups[0].pk
            : null,
      })
      setKelas(newKelas)
    }
  }, [props.kelas])

  useEffect(() => {
    if (amount) {
      formik.setValues({
        ...formik.values,
        total_price: Number(amount),
      })
    }
  }, [amount])

  const classType =
    props.kelas?.type === "TS"
      ? "TamanClass Seikhlasnya"
      : props.kelas?.type === "TP"
      ? "TamanClass+"
      : props.kelas?.type === "AP"
      ? "AfterClass+"
      : "AfterClass Seikhlasnya"

  return (
    <MustLoggedInWrapper>
      <Styles>
        <Sidebar>
          <SidebarLeft className="sidebar-left">
            <div className="class-photo-wrapper">
              <div
                className="class-photo"
                style={
                  kelas?.logo
                    ? {
                        backgroundImage: `url(${kelas.logo})`,
                      }
                    : { backgroundColor: "gray" }
                }
              />
            </div>
            <div className="left-nav">Pembayaran</div>
          </SidebarLeft>
          <SidebarRight className="sidebar-right">
            <form onSubmit={formik.handleSubmit}>
              <div className="daftar-kelas">
                Pembayaran Kelas{" "}
                <div>
                  <Image
                    imgName={`${classType}.png`}
                    alt={classType}
                    className="class-type"
                    onClick={() => props.onClickTipe(classType)}
                    imgStyle={{ objectFit: "contain" }}
                  />
                </div>
              </div>
              <h1>
                {kelas.name}{" "}
                {kelas.is_verified && (
                  <img
                    className="is-verified"
                    src={verifiedLogo}
                    alt="verified"
                  />
                )}
              </h1>
              <br />
              <div style={{ paddingTop: "1rem" }}>
                Jumlah Pembayaran (dalam Rupiah)
              </div>
              <Input
                id="id_total_price"
                name="total_price"
                type="number"
                placeholder="10000"
                value={formik.values.total_price}
                onChange={formik.handleChange}
                required
                min={1}
                readOnly={Boolean(amount)}
              />
              <br />
              <br />
              <div className="btn-checkout">
                <Button disabled={loading}>
                  {loading ? "Loading..." : "CHECKOUT"}
                </Button>
              </div>
            </form>
          </SidebarRight>
        </Sidebar>
      </Styles>
    </MustLoggedInWrapper>
  )
}

export default PayComponent
